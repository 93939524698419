
import { defineComponent, ref } from "vue";
import useUpload from "./useUpload";
export default defineComponent({
  name: "UploadAvatar",
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    name: {
      type: String,
      default: "",
    },
    isClick: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const { img, onCrop, base64, onChange, isShowCrop, onCancel , originSrc } = useUpload(
      props,
      ctx
    );

    return {
      img,
      onCrop,
      base64,
      isShowCrop,
      onChange,
      onCancel,
      originSrc
    };
  },
});
